.toggle_btn [class~="componnt_lib_toggle_btn_wraper"] {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
}
.toggle_btn[class~="componnt_lib_toggle_btn_active"] {
  background: theme("colors.interactive-secondary-default");
  border-color: theme("colors.interactive-secondary-default") !important;
  color: theme("colors.surface-neutral-option-1") !important;
}
.toggle_btn[class~="componnt_lib_toggle_btn_active"]
  [class~="iconCompLibrary_checkCircleIcon_path_"] {
  fill: theme("colors.surface-neutral-option-1");
  stroke: theme("colors.interactive-secondary-default");
}
.toggle_btn[class~="componnt_lib_toggle_btn_active"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
  background: theme("colors.interactive-secondary-default");
}
.toggle_btn[class~="componnt_lib_toggle_btn_inactive"] {
  border-color: theme("colors.interactive-secondary-default") !important;
}
.toggle_btn[class~="componnt_lib_toggle_btn_inactive"]:hover {
  border-width: 2px !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4);
}
.toggle_btn[class~="componnt_lib_toggle_btn_active"]:focus {
  border-color: theme("colors.interactive-secondary-default") !important;
}

.secondary.focus_class[class~="keyBoardFocused"]:focus {
  border-color: theme("colors.border-strong");
}

.toggle_btn:focus-visible {
  outline: unset;
}

.amt_togglebtn_wrapper [class~="componnt_lib_toggle_btn"]:last-child {
  margin-bottom: 0;
}

.amt_ans_togglebtn_wrapper [class~="componnt_lib_toggle_btn"]:last-child {
  margin-bottom: 0;
}

.policy_togglebtn_wrapper [class~="componnt_lib_toggle_btn_label"] {
  font-weight: 600;
}

.policy_togglebtn_wrapper:last-child {
  margin-bottom: 0;
}

.policy_togglebtn_wrapper [class~="componnt_lib_toggle_btn_description"] {
  color: theme("colors.surface-neutral-option-8");
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.policy_togglebtn_wrapper:active
  [class~="componnt_lib_toggle_btn_description"] {
  color: theme("colors.surface-neutral-option-1");
}

.policy_togglebtn_wrapper[class~="componnt_lib_toggle_btn_active"]
  [class~="componnt_lib_toggle_btn_description"] {
  color: theme("colors.surface-neutral-option-1");
}

.review_auto_renewal_togglebtn_wrapper
  [class~="componnt_lib_toggle_btn_wraper"] {
  @apply sm:items-start md:items-center !important;
}

.review_auto_renewal_togglebtn_wrapper
  [class="componnt_lib_toggle_btn_body_wrapper"] {
  width: calc(100% - 24px);
}

.medical_gatekeeper_toggle_btn[class~="componnt_lib_toggle_btn_inactive"]:hover {
  border-color: theme("colors.secondary-inactive-hover") !important;
}

.medical_gatekeeper_toggle_btn[class~="componnt_lib_toggle_btn_active"]:hover {
  border-color: theme("colors.secondary-active-hover") !important;
  box-shadow: 0px 0px 0px 2px rgba(40, 86, 110, 0.4) !important;
}

.focus_class[class~="keyBoardFocused"]:focus-visible,
.medical_gatekeeper_toggle_btn:not(:focus):focus-visible {
  outline: 2px solid theme("colors.interactive-focus-default");
  outline-offset: 4px;
}
