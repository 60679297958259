@media screen and (min-width: 600px) {
  .homepageWrapper:not(:has(footer)) {
    min-height: calc(100vh - 12px);
  }

  .homepageWrapper .main_wrapper:not(:has(footer)) {
    height: auto;
    overflow: unset;
  }

  .homepageWrapper:has(footer) {
    height: 100%;
  }

  .main_wrapper:has(footer) {
    min-height: calc(100vh - 12px);
  }

  .main_wrapper:not(:has(footer)) {
    height: calc(100vh - 12px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .main_wrapper:not(:has(footer)) main,
  .grid_block,
  .question_layout_wrapper,
  .question_layout {
    height: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .main_wrapper:not(:has(footer)) main,
  .grid_block,
  .question_layout_wrapper,
  .question_layout {
    height: auto;
  }
}

@media screen and (max-height: 900px) and (orientation: landscape) {
  .homepageWrapper:not(:has(footer)) {
    height: auto;
  }
  .homepageWrapper .main_wrapper:not(:has(footer)) {
    height: auto;
  }
}

@media screen and (min-width: 600px) and (orientation: portrait) {
  .homepageWrapper:not(:has(footer)) {
    height: 100vh;
    overflow-y: auto;
  }

  .homepageWrapper .main_wrapper:not(:has(footer)) {
    height: 100%;
    overflow-y: hidden;
  }

  .main_wrapper:not(:has(footer)) .question_layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .main_wrapper:not(:has(footer)) .question_layout .logo_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .main_wrapper:not(:has(footer)) .question_layout .content_box {
    padding-top: 116px;
  }
}

@media screen and (max-width: 600px) {
  .main_wrapper:not(:has(footer)) main {
    padding-bottom: 80px;
  }
}
