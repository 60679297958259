.curved_corner_top_right {
  width: 26px;
  height: 26px;
  overflow: hidden;
  top: 0;
  left: -24px;
}

.curved_corner_top_right::before {
  top: 1px;
  right: 2px;
  box-shadow: 13px -13px 0 0 #f0f0f0;
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

.curved_corner_bottom_right {
  width: 16px;
  height: 16px;
  overflow: hidden;
  bottom: -15px;
  right: 0px;
}

.curved_corner_bottom_right::after {
  top: 1px;
  right: 1px;
  box-shadow: 8px -8px 0 0 #f0f0f0;
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

.get_help [class~="helpButton-body-section-1"] {
  font-family: theme("fontFamily.semibold-font") !important;
  font-weight: 600;
}
